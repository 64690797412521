.payment-container{
    max-width: 30rem;
    margin: 0 25% ;
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.5);
    padding: 2rem;
    border-radius: 2rem;
}
.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    border-radius: 1rem;
}
.body{
    padding: 2rem 0;
}
.tick-success{
    height: 100px;
    width: 100px;
}
.details{
    display: flex;
    justify-content: space-between;
}
.pdf-section button{
    width: 100%;
    border-radius: 5px;
}