.offer-box{
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.offer-body{
    position: relative;
    background-color: white;
    border-radius: 2rem;
    height: 90%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.close-btn{
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    display: flex;
    justify-content: end;
    margin: 1rem;
    cursor: pointer;
}
.close-btn img {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    height: 2rem;
    width: 2rem;
}
.offer-img{
    height: 90%;
    width: 80%;
}
.btn-redirect{
    width: 100%;
    margin: 0.5rem 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}