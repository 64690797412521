.box-container{
    display: flex;
    justify-content: space-between;
}
form{
    width: 40%;
    box-shadow: 4px 5px 8px rgba(0, 0, 0, 0.5);
    margin: 0 2%;
    padding: 2rem;
    border-radius: 2rem;
}
hr{
    margin-top: 1rem;
}
a{
    text-decoration: underline;
    color: lightskyblue;
}
.btn-footer{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.btn-footer button{
    width: 100%;
    background-color: #0B5ED7;
}
.btn-footer button:hover{
    width: 100%;
    background-color: #1d52a1;
}
.details img {
    height: 300px;
    width: 500px;
    border-radius: 2rem;
    margin: 0 5%;
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}