
.example {
  position: relative;
  padding: 0;
  max-width: 100%;
  display: block;
  cursor: pointer;
  overflow: hidden;
  width:200px;
}
.text {
    opacity: 0;
    font-size: 40px;
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    color: darkblue;
    font-weight: bold;
    background-color: rgba(200, 200, 200, 0.5);
    width: 200px;
    height: 200px;
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-align: center;
    margin:0 auto;
  }
  
.example .text:hover {
  opacity: 1;
}

.example .text {
  /* height: 150px; */
  opacity: 0;
  transition-delay: 0s;
  transition-duration: 0.4s;
}

.example .text:hover {
  opacity: 1;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
}

.nav-item:hover .dropdown:hover .dropdown-menu {display: inline-block;}
.dropdown:hover .dropdown-menu:hover  .dropdown-submenu{display: block;}

.offcanvas-backdrop.show {
  opacity: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

.on
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 60%;
  font-size: 15px;
  padding:5px;
  font-family: Verdana, sans-serif;
  width:100%;
}
.off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 100%;
  font-size: 15px;
  padding:5px;
  font-family: Verdana, sans-serif;
  /* right: inherit; */
  width:100%;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.nav-tabs .nav-link.active {
  color: rgb(12,63,116);
  border-color: rgb(214,158,46);
}
.nav-tabs .nav-link .span.active {
  background-color: rgb(12,63,116);
  border-color: rgb(214,158,46);
  
}
.nav-tabs .nav-link {
  color: rgb(214,158,46);
}
.nav-tabs .nav-link .span {
  /* color: rgb(214,158,46); */
  background-color: rgb(214,158,46);
  color:white;
}

.item{
  background-color: white;
  color: rgb(12,63,116);
  padding: 3px;
}
.item:hover{
  background-color: rgb(214,158,46);
  color: white;
}
.carousel-inner1{
  width:80%;
  height:500
}
.slick-prev, .slick-next{
  background-color: black !important;
  padding:15px;
  width: auto;
  height: auto;
  border-radius: 45px;
  /* position: relative !important; */
}
.slick-prev {
  left: -60px;
}
.slick-next {
  right: -60px;
}
table tr{
  
  /* border:1px solid green; */
}
table td{
  
  text-align: left;
  border-bottom:1px solid lightgray;
  font-size: medium;
  font-weight: 500;
  padding:15px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:400px;
}

.container > div {
  flex-grow: 1;
}
.element{
  width: 100vh;
  height: 100hv;
}