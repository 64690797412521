.card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
    justify-content: space-between;
    border-radius: 1rem;    
}
.card div img{
    height: 300px;
    width: 500px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); */
    border-radius: 1rem;
    margin: 2% 0;
}
.room-names{
    font-size: 1rem;
}
.gst-charge{
    color: red;
}
.div-btn{
    display: flex;
    justify-content: right;
    height: 90%;
    align-items: flex-end;
}
.check-btn{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.hotel-info-table{
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}
.select-room{
    border: 1px dashed gray;
    outline: none;
    width: 7.5rem;
}

/* Chrome, Safari, Edge, Opera */
.select-room::-webkit-outer-spin-button,
.select-room::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.select-room[type=number] {
  -moz-appearance: textfield;
}